<template>
  <span v-if="hasAuthization">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          @click="linkTo"
          class="mx-1"
          fab
          x-small
          color="blue"
        >
          <v-icon >mdi-account-outline</v-icon>
        </v-btn>
      </template>
      <span >Voir le detail</span>
    </v-tooltip>
  </span>
</template>

<script>

import {mapGetters} from "vuex"

export default {
  components: {  },

  data: () => ({
    modalIsOpen: false,
    currentUserId: null,
    mounted: false,
    
  }),

  created() {
    //this.currentUserId = this.user.ID_Utilisateur;
    
    this.mounted = true;
  },

  computed: {
    ...mapGetters('user', ['can']),

    hasAuthization() {
      return this.can('gestion_comptabilite__gestion_des_factures')
    },

    

  },

  methods: {
    closeModal() {
      this.modalIsOpen = false;
    },

    openModal() {
      this.modalIsOpen = true;
    },

    linkTo() {
        return this.$router.push({ 
          name: 'gestion_comptabilite__detail_facture',
          params: { id: this.item.id },
        });
    }

  },

  props: {
    context: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
};
</script>
